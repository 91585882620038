import { useAuth } from '@IJGN/potential'
import type { MetaFunction } from '@remix-run/node'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSharedData } from '~/context/SharedDataProvider'
import CenterLoading from './components/Loading/Password'

export const meta: MetaFunction = () => {
  return [{ title: 'New Remix App' }, { name: 'description', content: 'Welcome to Remix!' }]
}

export default function Index() {
  const { user } = useAuth()
  const { userRoles, company } = useSharedData()
  const navigate = useNavigate()

  useEffect(() => {
    if (userRoles.includes('admin')) {
      navigate('/admin/dashboard')
    } else if (userRoles.includes('company_person')) {
      if (!user || !company) {
        navigate('/companies/mypage')
      } else {
        navigate(`/companies/${company?.code}/dashboard`)
      }
    } else {
      navigate('/login')
    }
  }, [user, userRoles, company, navigate])

  return <CenterLoading />
}
